import React, { useContext } from 'react';
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"
import { ThemeContext } from 'providers/ThemeProvider';
import { Wrapper } from './styles'

const languageName = {
  en: "EN",
  ja: "JA",
}

const Language = () => {
  const { theme } = useContext(ThemeContext);
  return (
    <Wrapper>
      <IntlContextConsumer>
        {({ languages, language: currentLocale }) =>
          languages.map(language => (
            <a
              key={language}
              onClick={() => changeLocale(language)}
              style={{
                color: currentLocale === language ? (theme === 'light' ? '#f57c00': `#00e676`) : theme === 'light' ? 'black': 'white',
                margin: 10,
                textDecoration: `None`,
                cursor: `pointer`,
              }}
            >
              {languageName[language]}
            </a>
          ))
        }
      </IntlContextConsumer>
    </Wrapper>
  )
}

export default Language