module.exports = {
  defaultTitle: 'Ozora Ogino',
  logo: '',
  author: 'Ozora Ogino',
  url: 'https://ozora-ogino.com',
  legalName: 'Ozora Ogino',
  defaultDescription: 'I’m Ozora and I’m a fullstack web developer!',
  socialLinks: {
    twitter: 'http://www.twitter.com/ozora1127',
    github: 'https://github.com/ozora-ogino',
    linkedin: 'https://www.linkedin.com/in/',
    instagram: 'https://instagram.com/',
    youtube: 'https://www.youtube.com/',
    google: 'https://plus.google.com/u/0/xxx',
  },
  googleAnalyticsID: 'UA-197919237-1',
  themeColor: '#6b63ff',
  backgroundColor: '#6b63ff',
  social: {
    facebook: 'appId',
    twitter: '@ozora1127',
  },
  address: {
    city: 'Koganei',
    region: 'Tokyo',
    country: 'Japan',
    zipCode: 'xxx-xxxx',
  },
  contact: {
    email: 'ozora.ogino.1127@gmail.com',
    phone: '',
  },
  foundingDate: '2020',
};
