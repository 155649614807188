import styled from 'styled-components';

export const Card = styled.div`
  padding: 1rem;
  background: ${({ theme }) => (theme === 'light' ? '#ffffff' : '#2e2d2d')};
  height: 100%;
`;

export const TitleWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ProjectsCard = styled.div`
  padding: 1rem;
  background: ${({ theme }) => (theme === 'light' ? '#ffffff' : '#2e2d2d')};
  height: 100%;

  h4 {
    color: ${({ theme }) => (theme === 'light' ? '#212121' : '#fff')};
  }

  p {
    color: ${({ theme }) => (theme === 'light' ? '#212121' : '#fff')};
  }
  
  a {
    color: ${({ theme }) => (theme === 'light' ? '#212121' : '#fff')};
  }
`;
