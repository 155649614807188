import styled from 'styled-components';

export const Wrapper = styled.div`
 
  @media (max-width: 960px) {
      padding-top: 1rem;
      margin-left: -1rem;
    a {
    }
  }
`;
