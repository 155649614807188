import React, {useContext} from 'react';
import { Link } from 'gatsby';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { ThemeContext } from 'providers/ThemeProvider';
import ToggleTheme from 'components/theme/Header/ToggleTheme';
import { Wrapper } from './styles';
import Language from 'components/theme/Language/'
import { useIntl } from "gatsby-plugin-intl"

const NavbarLinks = ({ desktop }) => {
  const { theme } = useContext(ThemeContext);
  const intl = useIntl()
  const current = {
    // color: theme === 'light' ? '#ffa000' : '#00e676',
    textDecoration: "underline",
    fontWeight: "bold",
  }

  return (
    <Wrapper desktop={desktop} theme={theme}>
      <Link  to={"/" + intl.locale + "/"}  activeStyle={current} >Home</Link>
      <Link  to={"/" + intl.locale + "/about"} activeStyle={current}  >About</Link>
      {/* <AnchorLink href="#projects">Projects</AnchorLink>
      <AnchorLink href="#contact">Contact</AnchorLink> */}
      <ToggleTheme />
      <Language />
    </Wrapper>
  )

};

export default NavbarLinks;
